import { Box, Flex, Text, Checkbox, Group } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import IdeaContext from "../../IdeaContext";
import FiltersContext from "../../../ideas/filters/context/FiltersContext";
import { SingleSideCheckbox } from "./SingleSideCheckbox";
// import { findKeyByPerspectiveDataType } from "../../NewIdeaView";
import { findFilterByName } from "../../../../context/helpers";
import SyncContext from "../../../../context/SyncContext";
import DiscoveryContext from "../../../Discovery/DiscoveryContext";

const PerspectiveList = ({ideaPage}:{ideaPage: boolean}) => {

  const ContextToUse: React.Context<any> = 
  ideaPage ? IdeaContext : DiscoveryContext;

  const {
    perspectiveData,
    type,
    ideaId,
    showmatches,
    showValidated,
    activeInboxTab,
    matches,
    requestsMatches,
    perspectiveCalculated,
  } = useContext(ContextToUse);

  const { activeFilters, setActiveFilters, setCheckedComponents } = useContext(FiltersContext);
  const { ideasFiltersConfig }  = useContext(SyncContext);
  const [allEvidenceChecked, setAllEvidenceChecked] = useState(false);
  const [noEvidenceChecked, setNoEvidenceChecked] = useState(true);
  const [largestTotal, setLargestTotal] = useState(0);
  const [largestValidated, setLargestValidated] = useState(0);
  const [largestSuggested, setLargestSuggested] = useState(0);
  const [largestInbox, setLargestInbox] = useState(0);
  const [dataArr, setDataArr] = useState([]);

  const requestsCount = perspectiveData[type]?.requestsCount;
  const inboxCount = perspectiveData[type]?.inboxCount;
  const matchesCount = perspectiveData[type]?.matchesCount;
  const CurrentFilterConfig = findFilterByName(type, 'evidence', ideasFiltersConfig)
  // const { key } = findKeyByPerspectiveDataType(type);
  // console.log("PerspectiveList",{ideasFiltersConfig, CurrentFilterConfig, type, CurrentFilterConfigkey: CurrentFilterConfig?.label})

  

  const handleCheckboxChange = (selectedArray: string[]) => {
    // console.log({selectedArray})

    setCheckedComponents((prev) => ({
      ...prev,
      [CurrentFilterConfig?.name]: selectedArray.map((i:string) => i.toLowerCase()),
    }));

    setActiveFilters &&
      setActiveFilters((prevFilters:any) => {
        const index = prevFilters.findIndex((filter:any) => (filter.name || filter.parent.split('.').pop()) === CurrentFilterConfig?.name);
        // console.log("PerspectiveList",{index})
        if (index !== -1) {
          // Update the existing filter
          const updatedFilters = [...prevFilters];
          updatedFilters[index] = {
            ...updatedFilters[index],
            ...CurrentFilterConfig,
            selected: selectedArray,
          };
          return updatedFilters;
        } else {
          // Add a new filter if it does not exist (optional based on your logic)
          return [
            ...prevFilters,
            {
              operator: "", // Set the default operator if necessary
              parent: CurrentFilterConfig?.name,
              ...CurrentFilterConfig,
              type: "checkbox", // Assuming type is 'checkbox'; adjust if necessary
              selected: selectedArray,
            },
          ];
        }
      });
  };

  const handleDisplayAll = (click: boolean) => {
    const allValues = dataArr?.map((a: any) => (a = a.value.toLowerCase()));
    // console.log("PerspectiveList",{allValues})
    setActiveFilters((prevFilters:any) => {
      // console.log('prevFilters', prevFilters)
      const index = prevFilters.findIndex((filter:any) => (filter.name || filter.parent.split('.').pop()) === CurrentFilterConfig?.name);
      if (index !== -1) {
        // Update the existing filter
        const updatedFilters = [...prevFilters];
        updatedFilters[index] = {
          ...updatedFilters[index],
          ...CurrentFilterConfig,
          selected: click ? allValues : [],
        };
        return updatedFilters;
      } else {
        // Add a new filter if it does not exist (optional based on your logic)
        return [
          ...prevFilters,
          {
            operator: "", // Set the default operator if necessary
            parent: CurrentFilterConfig?.name,
            ...CurrentFilterConfig,
            type: "checkbox", // Assuming type is 'checkbox'; adjust if necessary
            selected: click ? allValues : [],
          },
        ];
      }
    });
  };

  const currentValues =
    activeFilters.find((f) => f.name === CurrentFilterConfig?.name)?.selected || [];

  function arraysContainSameValues(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  useEffect(() => {
    const perspectiveArr = perspectiveData[type]?.dataArr;

    // Check if the data is available before running any logic
    if (!perspectiveArr || perspectiveArr.length === 0 || !activeFilters) {
      return; // Do nothing if the data is not ready
    }

    setDataArr(perspectiveArr);

    const activePerspectiveFilterValues =
      activeFilters.find((f) => f.name === CurrentFilterConfig?.name)?.selected || [];
    // console.log("PerspectiveList",{activePerspectiveFilterValues})
    // Handle no evidence checked state
    setNoEvidenceChecked(activePerspectiveFilterValues.length === 0);

    // Check if all values are selected
    const allValues = perspectiveArr.map((a: any) => a.value.toLowerCase());
    const allValuesSelected = arraysContainSameValues(
      allValues,
      activePerspectiveFilterValues
    );

    setAllEvidenceChecked(allValuesSelected);

    // Update largest values
    const largestT = perspectiveArr.reduce(
      (max: number, dataItem: any) => Math.max(max, dataItem.total),
      0
    );
    const largestV = perspectiveArr.reduce(
      (max: number, dataItem: any) => Math.max(max, dataItem.requestsCount),
      0
    );
    const largestS = perspectiveArr.reduce(
      (max: number, dataItem: any) => Math.max(max, dataItem.matchesCount),
      0
    );
    const largestI = perspectiveArr.reduce(
      (max: number, dataItem: any) => Math.max(max, dataItem.inboxCount),
      0
    );

    setLargestTotal(largestT);
    setLargestValidated(largestV);
    setLargestSuggested(largestS);
    setLargestInbox(largestI);
  }, [
    perspectiveData,
    activeFilters,
    // key,
    // CurrentFilterConfig,
    type,
    matches,
    requestsMatches,
    perspectiveCalculated,
    ideaId,
  ]);

  return (
    <Box my="23px">
      <Group mb={24} position="apart">
        <Group>
          <Checkbox
            indeterminate={!allEvidenceChecked && !noEvidenceChecked}
            checked={allEvidenceChecked}
            onChange={(event) => {
              setAllEvidenceChecked(event.currentTarget.checked);
              handleDisplayAll(event.currentTarget.checked);
            }}
            disabled={ Object.keys(ideasFiltersConfig.evidence).length == 0 }
            color="indigo"
            label={
              <Text fz="12px" lineClamp={1}>
                {" "}
                Display all evidence
              </Text>
            }
          />
        </Group>

        <Flex>
          {showValidated && (
            <Flex ml="10px">
              <Text mr="4px" color="#888D9B" fz="12px" fw={600}>
                Assigned
              </Text>
              <Text color="#9F9AF8" fz="12px" fw={600}>
                {requestsCount || 0}
              </Text>
            </Flex>
          )}
          {showmatches && !showValidated && (
            <Flex ml="10px">
              <Text mr="4px" color="#888D9B" fz="12px" fw={600}>
                Suggested
              </Text>
              <Text color="#9F9AF8" fz="12px" fw={600}>
                {matchesCount || 0}
              </Text>
            </Flex>
          )}
          {showmatches && showValidated && (
            <Flex ml="10px">
              <Text mr="4px" color="#888D9B" fz="12px" fw={600}>
                Suggested
              </Text>
              <Text color="#9F9AF8" fz="12px" fw={600}>
                {matchesCount + inboxCount || 0}
              </Text>
            </Flex>
          )}
          {activeInboxTab && (
            <Flex ml="10px">
              <Text mr="4px" color="#888D9B" fz="12px" fw={600}>
                Suggested
              </Text>
              <Text color="#9F9AF8" fz="12px" fw={600}>
                {inboxCount || 0}
              </Text>
            </Flex>
          )}
        </Flex>
      </Group>
      <Checkbox.Group value={currentValues} onChange={handleCheckboxChange}>
        {activeInboxTab &&
          dataArr &&
          dataArr.length > 0 &&
          [...dataArr]
            .sort((a: any, b: any) => a.inboxCount - b.inboxCount)
            .map(
              (item: any, index: number) =>
                item.inboxCount > 0 && (
                  <SingleSideCheckbox
                    key={index}
                    item={item}
                    validatedCount={largestValidated}
                    largestMatchesCount={largestSuggested}
                    largestInboxCount={largestInbox}
                    largestTotal={largestTotal}
                    disabled={ Object.keys(ideasFiltersConfig.evidence).length == 0 }
                    ideaPage={ideaPage}
                  />
                )
            )}

        {showValidated &&
          showmatches &&
          dataArr &&
          dataArr.length > 0 &&
          [...dataArr]
            .sort((a: any, b: any) => b.total - a.total)
            .map(
              (item: any, index: number) =>
                item.total > 0 && (
                  <SingleSideCheckbox
                    key={index}
                    item={item}
                    validatedCount={largestValidated}
                    largestMatchesCount={largestSuggested}
                    largestInboxCount={largestInbox}
                    largestTotal={largestTotal}
                    disabled={ Object.keys(ideasFiltersConfig.evidence).length == 0 }
                    ideaPage={ideaPage}
                  />
                )
            )}

        {showValidated &&
          !showmatches &&
          dataArr &&
          dataArr.length > 0 &&
          [...dataArr]
            .sort((a: any, b: any) => b.requestsCount - a.requestsCount)
            .map(
              (item: any, index: number) =>
                item.requestsCount > 0 && (
                  <SingleSideCheckbox
                    key={index}
                    item={item}
                    validatedCount={largestValidated}
                    largestMatchesCount={largestSuggested}
                    largestInboxCount={largestInbox}
                    largestTotal={largestTotal}
                    disabled={ Object.keys(ideasFiltersConfig.evidence).length == 0 }
                    ideaPage={ideaPage}
                  />
                )
            )}

        {!showValidated &&
          showmatches &&
          dataArr &&
          dataArr.length > 0 &&
          [...dataArr]
            .sort((a: any, b: any) => b.matchesCount - a.matchesCount)
            .map(
              (item: any, index: number) =>
                item.matchesCount > 0 && (
                  <SingleSideCheckbox
                    key={index}
                    item={item}
                    validatedCount={largestValidated}
                    largestMatchesCount={largestSuggested}
                    largestInboxCount={largestInbox}
                    largestTotal={largestTotal}
                    disabled={ Object.keys(ideasFiltersConfig.evidence).length == 0 }
                    ideaPage={ideaPage}
                  />
                )
            )}
      </Checkbox.Group>
    </Box>
  );
};

export default PerspectiveList;
