import { Accordion, Box,  ScrollArea, Stack, Text } from '@mantine/core'
import  {  useEffect, useState } from 'react'
import CardInfo from './CardInfo'
import UpperScroller from './UpperScroller'
import { useElementSize } from '@mantine/hooks'

const CardScroller = ({chosenEvidence, reviewMode, ideaPage}: {reviewMode: boolean, chosenEvidence: any, ideaPage: boolean}) => {

  const { ref , height } = useElementSize();
  const [value, setValue] = useState<string | null>(null)
  const [detailsOpened, setDetailsOpened] = useState(false)
    
  useEffect(() => {
    if(height < 700){
      setDetailsOpened(true)
      setValue('1')
    }
    else{
      setDetailsOpened(false)
      setValue(null)  
    }
  }, [height])

  return (
    <Stack justify='space-between'   h={`calc(100vh - ${reviewMode ? 250: 310}px)`}  /* h='100%' */ >
      <ScrollArea h="calc(100vh - 330px)" mah="calc(100vh - 330px)"> 
        <Box ref={ref}>
          <UpperScroller chosenEvidence={chosenEvidence}/>
        </Box>
      </ScrollArea> 
      <Box sx={{ borderTop: "1px solid #D8D8DB" }}>
        <Accordion value={value} onChange={setValue} chevronPosition='left' p={0} m={0}>
          <Accordion.Item value="1"  onClick={()=> setDetailsOpened(!detailsOpened)}>
            <Accordion.Control pl='32px'> 
              <Text fw={600} fz={14} >
               More info
              </Text>
            </Accordion.Control>
          </Accordion.Item>
        </Accordion> 
        {detailsOpened && <Box px="35px" h={180} sx={{ borderBottom: "1px solid #D8D8DB" }} >
          <CardInfo item={chosenEvidence} ideaPage={ideaPage} />
        </Box>}
      </Box>
    </Stack> 
  )
}
export default CardScroller