import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar, Text,
  Flex,
  Group,
  Header, Menu,
  createStyles, Grid, Divider, Box,
} from "@mantine/core";
import { IconLogout, IconSettings} from "@tabler/icons";
import {Link, useLocation} from "react-router-dom";
import { getInitials } from "../../utils/getInitials";
import LogoIcon from "./LogoIcon";
import React  from "react";
import InviteMembersDropdown from "./InviteMembersDropdown";
import Search from "./Search";
import NotificationsDropdown from "../notifications/NotificationsDropdown";
import { useEffect, useState } from "react";
import { listNotifications } from "../../Api";
import {logout} from "../../utils";
import { usePostHog } from "posthog-js/react";

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: Number(theme.spacing.md) * 1.5, // Explicitly convert to number
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: Number(theme.spacing.md), // Explicitly convert to number
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: "#161832",
    padding: `${Number(theme.spacing.xs)}px 0px`, // Convert to number if needed
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      color: "#5B60E3",
      "& $linkIcon": {
        color: "#5B60E3",
      },
    },
  },

  linkIcon: {
    color: "#161832",
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      color: "#5B60E3",
      "& $linkIcon": {
        color: "#5B60E3",
      },
    },
  },
}));

export const MainHeader = () => {
  
  const auth0 = useAuth0();
  const posthog = usePostHog()

  const [notifications, setNotifications] = useState<any[]>([])

  const getListNotifications = () =>{
    listNotifications(auth0)
      .then((noti: any) => {
        setNotifications(noti);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    getListNotifications()
  }, []);

  const { classes, cx } = useStyles();
  const { pathname } = useLocation();
  const user = auth0.user;
  const name = user ? user.name : '';
  const role = user ? user['bagel/role'] : '';
  const canInvite = role === "admin"
  const avatar = user?.picture ? (<Avatar src={user.picture} radius="xl" />) : (<Avatar color="cyan" radius="xl">
    {name && getInitials(name)}
  </Avatar>)
  return (
    <Header height={63}>
      <Flex
        h={63}
        p="xs"
        bg={"#5B60E3"}
        gap="md"
        justify="space-between"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <Group>
          <Link to={"/"}>
            <LogoIcon height={32} style={{ marginTop: "4px" }} />
          </Link>
          <Search />
        </Group>

        <Group>
          <NotificationsDropdown notifications={notifications} getListNotifications={getListNotifications} />
          { canInvite && <InviteMembersDropdown /> }
          <Menu width={300} shadow="md" position={"bottom-end"} withArrow>
            <Menu.Target>{avatar}</Menu.Target>
            <Menu.Dropdown p={0}>
              <Grid p={"md"}>
                <Grid.Col span={1}> </Grid.Col>
                <Grid.Col span={2}>{avatar}</Grid.Col>
                <Grid.Col span={5} ml={"xs"}>
                  <Text sx={{ fontWeight: 500 }} size={"sm"}>
                    {name}
                  </Text>
                  <Text sx={{ textTransform: "capitalize" }} size={"sm"}>
                    {role}
                  </Text>
                </Grid.Col>
              </Grid>
              <Divider />
              <Menu.Item p={0}>
                <Box p={"md"}>
                  <Link
                    to={"/settings"}
                    className={cx(classes.link, {
                      [classes.linkActive]: "/settings" === pathname,
                    })}
                  >
                    <IconSettings className={classes.linkIcon} stroke={1.5} />
                    <span>Settings</span>
                  </Link>
                </Box>
              </Menu.Item>
              <Divider />
              <Menu.Item p={0}>
                <Box p={"md"}>
                  <a
                    href="#"
                    className={classes.link}
                    onClick={() => {
                      posthog?.capture('logged_out')
                      logout(auth0);
                    }}
                  >
                    <IconLogout className={classes.linkIcon} stroke={1.5} />
                    <span>Logout</span>
                  </a>
                </Box>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Flex>
    </Header>
  );
};
