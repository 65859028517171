import {
  Grid,
  Group,
  Card,
  Button,
  Text,
  TextInput,
  Autocomplete,
  Stepper,
  Center,
  createStyles,
  Box,
  Textarea,
  Menu,
  Tooltip,
  Flex,
  Stack,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCalendar } from "@tabler/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { ModalContentInterface } from "./ModalContent";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addRequestsToIdea,
  createNewIdea,
  getSuggestionsForIdea,
  searchMembers,
} from "../../Api";
import { SourceIcon } from "../../utils/SourceIcon";
import { DatePicker } from "@mantine/dates";
import AutoCompleteInnerItem from "../idea/AutoCompleteInnerItem";
import { useNavigate } from "react-router-dom";
import { IdeaItem } from "./IdeaItem";
import { getBagelId } from "../../utils";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { InfoCard } from "../idea/InfoCard";
import { Link } from "./Link";
import SyncContext from "../../context/SyncContext";
import BagelAutocomplete from "../general/BagelAutocomplete";
import { Loader } from "../../icons/x-symbol-svgrepo-com";

const useStyles = createStyles((theme) => ({
  separator: {
    height: 2,
    borderTop: `2px dashed ${
      theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[4]
    }`,
    borderRadius: theme.radius.xl,
    backgroundColor: "transparent",
  },

  separatorActive: {
    height: 2,
    borderTop: `2px dashed #5B60E3`,
  },

  stepIcon: {
    borderColor: "transparent",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.white,
    borderWidth: 2,
    transition: "border-color 150ms ease, background-color 150ms ease",

    "&[data-completed]": {
      borderWidth: 2,
      backgroundColor: "#5B60E3",
      borderColor: "#5B60E3",
    },
    "&[data-progress]": {
      borderColor: "#5B60E3",
    },
  },

  step: {
    transition: "transform 150ms ease",
    "&[data-progress]": {
      transform: "scale(1.1)",
    },
  },
}));

export function CreateNewForm(props: ModalContentInterface) {
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const {
    defaultPriority,
    defaultStatus,
    components,
    sync,
    activeSource,
    domains,
    fields,
    displayState,
    evidenceDomain,
  } = useContext(SyncContext);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [ideaMatchesList, setIdeaMatchesList] = useState<any[]>([]);
  const [loading, setLoading] = useState<any>({
    create: false,
    detach: false,
    matches: false,
  });

  const [active, setActive] = useState(0);
  const [requestsList, setRequestsList] = useState<any[]>([]);
  const [componentMandatory, setComponentMandatory] = useState<boolean>(true);
  const [productId, setProductId] = useState<string | null>(null);
  const [domain, setDomain] = useState();
  const [mandatoryDomain, setMandatoryDomain] = useState(false);
  const [productArea, setProductArea] = useState("Unassigned");
  const [productAreaMandatory, setProductAreaMandatory] =
    useState<boolean>(false);
  const [componentsByDomain, setComponentsByDomain] = useState<any[]>(
    components || []
  );

  interface FormFields {
    title: string;
    description: string;
    priority: string;
    status: string;
    domain: string;
    component: string;
    ownerName: string;
    ownerId: string;
    rolloutDate: Date | null;
    state: string
  }

  const loadFields = () => {
    if (fields) {
      if (!fields?.components || fields?.components.length === 0) {
        setComponentMandatory(false);
        return;
      }
    }
  };

  const form = useForm<FormFields>({
    initialValues: {
      title: "",
      description: "",
      priority: defaultPriority || "medium",
      status: defaultStatus || "unprocessed",
      domain: "",
      component: "",
      ownerName: auth0.user?.name || "",
      ownerId: getBagelId(auth0.user),
      rolloutDate: null,
      state: 'active'
    },
    validate: (values) => ({
      title: values.title === "" && "Title is required",
      ownerName: values.ownerName === "" && "Owner is required",
    }),
  });

  const nextStep = () => {
    const newObj = {
      idea: form.values,
      requests: [],
      productItemId: productId,
    };
    if (active == 1) {
      if (form.validate().hasErrors) {
        return null;
      }

      if (productArea === "Unassigned") {
        setProductAreaMandatory(true);
      } else {
        setLoading((prevState: any) => ({
          ...prevState,
          create: true,
        }));
        createNewIdea(newObj, auth0)
          .then((response) => {
            const newId = response.idea?._id;
            if (newId) {
              setTimeout(() => navigate("/idea/" + newId), 1500);
            } else {
              console.log(`did not receive request id for new idea`, response);
            }
          })
          .catch(console.log)
          .finally(() => {
            setLoading((prevState: any) => ({
              ...prevState,
              create: false,
            }));
            props.setOpened(false);
          });
      }
    } else {
      setActive((current) => {
        if (form.validate().hasErrors) {
          return current;
        }

        return current < 3 ? current + 1 : current;
      });
    }
  };

  const prevStep = () =>
    setActive((current) => {
      const new_current = current > 0 ? current - 1 : current;

      return new_current;
    });

  const loadSuggestions = () => {
    setLoading((prevState: any) => ({
      ...prevState,
      matches: true,
    }));
    getSuggestionsForIdea(form.values.title, form.values.description, auth0)
      .then((data: any) => {
        console.log(data);
        setIdeaMatchesList(data?.ideaMatches || []);
        setRequestsList(data?.cxMatches.map((cx: any) => cx.item) || []);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() =>
        setLoading((prevState: any) => ({
          ...prevState,
          matches: false,
        }))
      );
  };
  const fetchOptions = async () => {
    searchMembers(form.values.ownerName, auth0)
      .then((response) => {
        setUsersList(
          response.map((item: any) => ({
            value: item.name,
            sources: item.sources,
            email: item.email,
            _id: item._id,
            name: item.name,
            key: item._id,
          }))
        );
      })
      .catch((e: any) => console.log(e));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (form.isTouched("ownerName")) {
        fetchOptions();
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [form.values.ownerName]);

  useEffect(() => {
    loadFields();
    fetchOptions();
  }, []);

  useEffect(() => {
    active > 0 && loadSuggestions();
  }, [active]);

  const firstStep = useRef<HTMLButtonElement>(null);
  const stepper = useRef<HTMLDivElement>(null);

  // const handleDetach = (id: string) => {
  //   const temp = [...addedFeedbacksList].filter((item) => item._id !== id);
  //   setAddedFeedbacksList(temp);
  // };

  const handleSuggestedRequestClick = (id: string) => {
    navigate(`/idea/${id}`);
    props.setOpened(false);
  };

  const linkProduct = (item: any) => {
    setProductId(item._id);
  };

  const filterComponentByDomain = (domainValue: string) => {
    const copy = [...components];
    const arr: any[] = copy.filter((s: any) => s.domain === domainValue) || [];
    setComponentsByDomain(arr);
    const names = arr.map((a: any) => a.name);
    if (!names.includes(productArea)) {
      setProductArea("Unassigned");
    }
  };
  return (
    <Box>
      <Center bg={"#f5f5f5"} p="xl" sx={{ borderBottom: "1px solid #E5E7EB" }}>
        <Stepper classNames={classes} w={"60%"} ref={stepper} active={active}>
          <Stepper.Step label="Idea info" ref={firstStep} />
          <Stepper.Step label="Related items" />
        </Stepper>
      </Center>
      <Grid mx={30} my="lg" w="-webkit-fill-available">
        {/* Status row */}
        {active === 0 && (
          <Grid hidden={active !== 0} w="-webkit-fill-available">
            <Grid.Col span={3}>
              <Text fz="sm" fw={700}>
                <span style={{ color: "red", verticalAlign: "middle" }}>*</span>{" "}
                Title
              </Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <TextInput
                placeholder="Enter idea title"
                withAsterisk
                {...form.getInputProps("title")}
              />
            </Grid.Col>

            {/* Description row */}
            <Grid.Col span={3}>
              <Text fz="sm" fw={700}>
                Description
              </Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <Textarea
                placeholder="Enter idea description"
                withAsterisk
                autosize
                minRows={4}
                maxRows={6}
                {...form.getInputProps("description")}
              />
            </Grid.Col>

            {/* link row */}
            <Grid.Col span={3}>
              <Text fz="sm" fw={700}>
                Sync
              </Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <Group>
                {activeSource ? (
                  <Link
                    request={console}
                    getRequestFn={() => undefined}
                    linkToProduct={linkProduct}
                  />
                ) : (
                  <InfoCard
                    label={sync ?? "jira"}
                    text="Link"
                    icon={
                      <SourceIcon
                        sourceName={sync ?? "jira"}
                        width={25}
                        height={25}
                      />
                    }
                    cardProps={{ opacity: 0.5 }}
                  />
                )}
              </Group>
            </Grid.Col>

            {/* Owner row */}
            <Grid.Col span={3}>
              <Text fz="sm" fw={700}>
                Owner
              </Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <Group>
                <Autocomplete
                  miw={300}
                  placeholder="Select owner"
                  itemComponent={AutoCompleteInnerItem}
                  value={form.values.ownerName || ""}
                  onChange={(val) => form.setFieldValue("ownerName", val)}
                  onItemSubmit={(item) =>
                    form.setFieldValue("ownerId", item._id)
                  }
                  data={usersList}
                  /*    nothingFound={
                  <Button fullWidth disabled leftIcon={<IconPlus size={18} />}>
                    Invite member
                  </Button>
                } */
                  filter={(value, user) =>
                    (user.name &&
                      user.name
                        .toLowerCase()
                        .includes(value.toLowerCase().trim())) ||
                    (user.email &&
                      user.email
                        .toLowerCase()
                        .includes(value.toLowerCase().trim()))
                  }
                  error={form.errors.ownerName || form.errors.ownerId}
                />
              </Group>
            </Grid.Col>
            {/* rolloutDate row */}
            {/* {displayState.rolloutDate && (
              <>
                <Grid.Col span={3}>
                  <Text fz="sm" fw={700}>
                    Exp. delivery date
                  </Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <Group>
                    <DatePicker
                      placeholder="Pick date"
                      icon={<IconCalendar size={16} />}
                      value={form.values.rolloutDate}
                      onChange={(_date: Date) =>
                        _date !== null &&
                        form.setFieldValue("rolloutDate", _date)
                      }
                      minDate={new Date()}
                    />
                  </Group>
                </Grid.Col>
              </>
            )} */}
          </Grid>
        )}
        {active === 1 && (
          <Grid w="-webkit-fill-available" mt="md">
            {/* Product area row */}
            <Box>
              {displayState.domain && (
                <Flex m="xl">
                  <Text fz="sm" fw={700} w="200px">
                    <span style={{ color: "red", verticalAlign: "middle" }}>
                      *
                    </span>{" "}
                    Domain
                  </Text>

                  {/*  <Grid.Col span={9}> */}
                  <Menu shadow="md" width={200}>
                    <Menu.Target>
                      <Box onClick={() => setMandatoryDomain(false)}>
                        <Button w={160} variant="default">
                          <Text fw={400}>
                            {domain ? domain : "Select domain"}
                          </Text>
                        </Button>
                        {mandatoryDomain && !domain ? (
                          <Text color="red" fz="11.6px" mt="2px" h={10}>
                            Please select domain before selecting product area
                          </Text>
                        ) : (
                          <Text color="red" fz="11.6px" mt="2px" h={10}></Text>
                        )}
                      </Box>
                    </Menu.Target>

                    <Menu.Dropdown mt={-10}>
                      <Menu.Label>Choose one...</Menu.Label>
                      {domains &&
                        domains.length > 0 &&
                        domains.map((item: any) => (
                          <>
                            <Menu.Item
                              key={item.name}
                              onClick={() => {
                                setDomain(item.name);
                                filterComponentByDomain(item.name);
                                form.setFieldValue("domain", item.name);
                              }}
                            >
                              {item.name}
                            </Menu.Item>
                          </>
                        ))}
                    </Menu.Dropdown>
                  </Menu>
                </Flex>
              )}
              {/* </Grid.Col> */}
              {/* Product area row */}
              <Flex m="xl">
                <Text fz="sm" fw={700} w="200px">
                  <span style={{ color: "red", verticalAlign: "middle" }}>
                    *
                  </span>{" "}
                  Product area
                </Text>

                <BagelAutocomplete
                  disabled={!domain && displayState.domain}
                  items={
                    displayState.domain && evidenceDomain
                      ? [...components].filter((s: any) => s.domain == domain)
                      : components
                  }
                  selected={productArea}
                  onSelect={(item) => {
                    setProductArea(item.name);
                    setProductAreaMandatory(true);
                    form.setFieldValue("componentId", item._id);
                  }}
                >
                  <Tooltip
                    label={productArea ? "Product area" : "Select product area"}
                  >
                    <Button w={160} variant="default">
                      <Text fw={400}>
                        {capitalizeFirstLetter(productArea || "unassigned")}
                      </Text>
                    </Button>
                  </Tooltip>
                </BagelAutocomplete>
              </Flex>
            </Box>
            {
              <Grid.Col span={12}>
                <br />
                <br />

                {loading["matches"] ? (
                  <Card>
                    <Stack justify="center" align="center" mb="lg">
                      <Text align="center">
                        Loading suggestions for similiar ideas
                      </Text>
                      <Loader height={74} width={74} />
                    </Stack>
                  </Card>
                ) : (
                  <Box>
                    {ideaMatchesList.length > 0 ? (
                      <Box>
                        <>
                          <Text size="sm" fw={600}>
                            Could one of these ideas be considered?
                          </Text>
                          <Text size="sm" fw={400}>
                            (up to three times faster resolution with no
                            duplicates)
                          </Text>
                        </>
                        {ideaMatchesList.slice(0, 3).map((item) => (
                          <IdeaItem
                            key={item.ideaId}
                            idea={item.idea}
                            fromCreateIdea
                            loading={loading[item.ideaId] || false}
                            assignFunc={() =>
                              handleSuggestedRequestClick(item.ideaId)
                            }
                            isCreateNew
                            requestsCount={0}
                          />
                        ))}
                      </Box>
                    ) : (
                      <Box>
                        <Text size="sm" fw={600}>
                          No similiar ideas found
                        </Text>
                      </Box>
                    )}
                  </Box>
                )}
                {!(ideaMatchesList.length == 0 || loading["matches"]) && (
                  <Card withBorder p="xs" my="xs">
                    <Text size="sm" fw={400} align="center">
                      None of above matches
                    </Text>
                    <Group m="xs" position="center">
                      <Button
                        variant="default"
                        onClick={prevStep}
                        styles={() => ({
                          root: {
                            borderColor: "#5B60E3",
                            color: "#5B60E3",
                            height: 35,
                          },
                        })}
                        radius="md"
                      >
                        Back
                      </Button>

                      <Button
                        onClick={nextStep}
                        styles={(theme) => ({
                          root: {
                            backgroundColor: "#5B60E3",
                            border: 0,
                            height: 35,
                            "&:hover": {
                              backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                            },
                          },

                          leftIcon: {
                            marginRight: 15,
                          },
                        })}
                        // px="xl"
                        radius="md"
                        loading={loading["create"]}
                        disabled={active == 1 && !productAreaMandatory}
                      >
                        Create new idea
                      </Button>
                    </Group>
                  </Card>
                )}
              </Grid.Col>
            }
          </Grid>
        )}
      </Grid>
      {!(ideaMatchesList.length > 0 && active == 1) && (
        <Group
          m={30}
          position="right"
          mt="xl"
          // hidden={ideaMatchesList.length > 0 && active == 1}
        >
          {active !== 0 && (
            <Button
              variant="default"
              onClick={prevStep}
              styles={() => ({
                root: {
                  borderColor: "#5B60E3",
                  color: "#5B60E3",
                  height: 35,
                },
              })}
              radius="md"
            >
              Back
            </Button>
          )}
          {active !== 3 && (
            <Button
              onClick={nextStep}
              styles={(theme) => ({
                root: {
                  backgroundColor: "#5B60E3",
                  border: 0,
                  height: 35,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              px="xl"
              radius="md"
              disabled={
                (active == 1 && loading["create"]) ||
                (active == 1 && !productAreaMandatory)
              }
              loading={active == 1 && loading["create"]}
            >
              {active == 1 ? "Create" : "Next step"}
            </Button>
          )}
        </Group>
      )}
    </Box>
  );
}
