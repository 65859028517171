import {Accordion, Button, Flex, Grid, Text} from "@mantine/core";
import { IconCategory, IconCoin } from "@tabler/icons";
import { useContext, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import SyncContext from "../../../context/SyncContext";

export const EvidenceCard = (props: {
  cxItem?: any;
  company?: any;
  requestText?: string;
  contact?: any;
  opType?: string;
}) => {
  const { opportunityAdditionalInfo } = useContext(SyncContext);
  const { contact, cxItem } = props;
  const contactName =
    contact && contact?.name
      ? contact?.name
      : contact && contact?.email
        ? contact?.email
        : "Unknown User";
  const requestType = cxItem ? cxItem?.itemType : "manual";
  const amount = cxItem?.amount ??  null;
  const oppurtunityType = cxItem?.opportunityType ? cxItem?.opportunityType : cxItem?.opportunityType ?? null;
  const [show, setShow] = useState(true)
  const [showOp, setShowOp] = useState(true)
  
  const getConversationCard = () => {
    return (
      <>
        <Accordion.Item value="opportunity" mb={10}>
          <Accordion.Control
            bg="#F8F9FB"
            h={44} /* sx={{ borderRadius: "8px" }} */
          >
            <Flex h={44} onClick={()=> setShow(!show)}>
              <Flex
                w={"100%"}
                p="md"
                pl={0}
                justify="space-between"
                align="center"
                sx={{ borderRadius: "8px" }}
              >
                <Flex justify='space-between' align="center">
                  {/* <IconCaretRight fill="" size={22} /> */}
                  <Text>Conversation</Text>
                </Flex>
                <Flex>
                  {show &&
                    <Button variant="light" compact color="gray" m={4}>
                      <Text mr={4} mt={5}>
                        {" "}
                        <IconCategory color="black" size={14} />{" "}
                      </Text>
                      <Text color="black" fw="lighter">
                        {cxItem?.conversationType
                          ? cxItem?.conversationType
                          : "Conversation"}
                      </Text>
                    </Button>
                  }
                </Flex>
              </Flex>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Grid>
              
              {cxItem?.status &&<> <Grid.Col span={3}>
                <Text color="#6B7083"  ml={5}> Status </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Flex
                  align="center"
                  justify="center"
                  p={6}
                  bg="#fff"
                  h={32}
                  sx={{ borderRadius: "10px" }}
                >
                  <Text color="black" fw="lighter">
                    {" "}
                    {cxItem?.status}
                  </Text>{" "}
                </Flex>
              </Grid.Col></>}
              
              
              {cxItem?.priority &&<><Grid.Col span={3}>
                <Text color="#6B7083"  ml={5}> Priority: </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Flex
                  align="center"
                  justify="center"
                  p={6}
                  bg="#fff"
                  
                  h={32}
                  sx={{ borderRadius: "10px" }}
                >
                  <Text color="black" fw="lighter">
                    {" "}
                    {cxItem?.priority.replaceAll("_", " ")}
                  </Text>{" "}
                </Flex>
              </Grid.Col>
              </>}
              {(cxItem?.closedAt || cxItem?.updatedAt) && <><Grid.Col span={3}>
                <Text color="#6B7083" ml={5}>
                  {" "}
                  {(!cxItem?.isOpen && cxItem?.closedAt) ? "Close Date" : "Modified Date"}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Flex
                  align="center"
                  justify="center"
                  p={6}
                  bg="#fff"
                  
                  h={32}
                  sx={{ borderRadius: "10px" }}
                >
                  <Text color="black" fw="lighter">
                    <ReactTimeAgo
                      date={
                        new Date(
                          !cxItem?.isOpen && cxItem?.closedAt
                            ? cxItem?.closedAt
                            : cxItem?.updatedAt
                        )
                      }
                      locale="en-US"
                    />
                  </Text>
                  
                </Flex>
              </Grid.Col></>}
              
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>
      </>
    );
  };

  const getOpportunityCard = () => {
    return (
      <Accordion.Item value="opportunity" mb={10}>
        <Accordion.Control
          bg="#F8F9FB"
          h={44} /* sx={{ borderRadius: "8px" }} */
        >
          <Flex h={44}>
            <Flex
              w={"100%"}
              justify="space-between"
              align="center"
              p="md"
              pl={0}
              sx={{ borderRadius: "8px" }}
              onClick={() => setShowOp(!showOp)}
            >
              <Flex justify="space-between" align="center">
                {/* <IconCaretRight fill="" size={22} /> */}
                <Text>Opportunity</Text>
              </Flex>
              <Flex>
                {amount && amount !== 0 && showOp && (
                  <Button variant="light" compact color="gray" m={4}>
                    <Text mr={4} mt={5}>
                      <IconCoin color="black" size={18} />
                    </Text>
                    <Text fz={12} mr={2} color="black">
                      {" "}
                      ${" "}
                    </Text>
                    <Text color="black" fw="lighter">
                      {" "}
                      {amount.toLocaleString()}
                    </Text>
                  </Button>
                )}
                {oppurtunityType && showOp && (
                  <Button variant="light" compact color="gray" m={4}>
                    <Text mr={4} mt={5}>
                      {" "}
                      <IconCategory color="black" size={14} />{" "}
                    </Text>
                    <Text color="black" fw="lighter">
                      {oppurtunityType}
                    </Text>
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Accordion.Control>
        <Accordion.Panel>
          <Grid>
            <Grid.Col span={3}>
              <Text color="#6B7083" ml={5}>
                {" "}
                Amount:{" "}
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Flex
                align="center"
                justify="center"
                p={6}
                bg="#fff"
                w={"100%"}
                h={32}
                sx={{ borderRadius: "10px" }}
              >
                <Text mr={4} mt={5}>
                  <IconCoin color="black" size={18} />
                </Text>
                <Text fz={12} mr={2} color="black">
                  {" "}
                  ${" "}
                </Text>
                <Text color="black" fw="lighter">
                  {" "}
                  {amount ? amount.toLocaleString() : ""}
                </Text>{" "}
              </Flex>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="#6B7083" pl={6} ml={6}>
                {" "}
                Stage:{" "}
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Flex
                align="center"
                justify="center"
                p={6}
                bg="#fff"
                h={32}
                sx={{ borderRadius: "10px" }}
              >
                <Text color="black" fw="lighter">
                  {" "}
                  {cxItem?.stageName ?? ""}
                </Text>{" "}
              </Flex>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="#6B7083" ml={5}>
                {" "}
                Oppurtunity type:{" "}
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Flex
                align="center"
                justify="center"
                p={6}
                bg="#fff"
                h={32}
                sx={{ borderRadius: "10px" }}
              >
                <Text mr={4} mt={5}>
                  <IconCategory color="black" size={14} />
                </Text>
                <Text color="black" fw="lighter">
                  {" "}
                  {oppurtunityType}
                </Text>{" "}
              </Flex>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="#6B7083" pl={6} ml={6}>
                {" "}
                Probability:{" "}
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Flex
                align="center"
                justify="center"
                p={6}
                bg="#fff"
                h={32}
                sx={{ borderRadius: "10px" }}
              >
                <Text color="black" fw="lighter">
                  {" "}
                  {cxItem?.probability ?? ""}%
                </Text>{" "}
              </Flex>
            </Grid.Col>
            {cxItem?.closedAt && (
              <>
                <Grid.Col span={3}>
                  <Text color="#6B7083" ml={5}>
                    {" "}
                    Close date:{" "}
                  </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Flex
                    align="center"
                    justify="center"
                    p={6}
                    bg="#fff"
                    h={32}
                    sx={{ borderRadius: "10px" }}
                  >
                    <Text color="black" fw="lighter">
                      <ReactTimeAgo date={new Date(cxItem?.closedAt)} />
                    </Text>{" "}
                  </Flex>
                </Grid.Col>
              </>
            )}
            {cxItem?.renewalDate && (
              <>
                <Grid.Col span={3}>
                  <Text color="#6B7083" pl={6} ml={6}>
                    {" "}
                    Renewal date:{" "}
                  </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Flex
                    align="center"
                    justify="center"
                    p={6}
                    bg="#fff"
                    h={32}
                    sx={{ borderRadius: "10px" }}
                  >
                    <Text color="black" fw="lighter">
                      {" "}
                      <ReactTimeAgo date={new Date(cxItem?.renewalDate)} />
                    </Text>{" "}
                  </Flex>
                </Grid.Col>
              </>
            )}
          </Grid>
          {opportunityAdditionalInfo && Object.keys(opportunityAdditionalInfo)?.length > 0 && (
            <>
              <Text ml={5} my="md">
                Additional info
              </Text>
              <Grid>
                {opportunityAdditionalInfo && Object.keys(opportunityAdditionalInfo)?.map((key: any)=> {
                  const field = opportunityAdditionalInfo[key]
                  // console.log(cxItem)
                  return cxItem[key] ? <> <Grid.Col span={3}>
                    <Text color="#6B7083" ml={5}>
                      {field.label}:
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Flex
                      align="center"
                      justify="center"
                      p={6}
                      bg="#fff"
                      w={"100%"}
                      h={32}
                      sx={{ borderRadius: "10px" }}
                    >
                      <Text color="black" fw="lighter">
                        {cxItem[key]}
                      </Text>{" "}
                    </Flex>
                  </Grid.Col></> : <></>
                } )}
              </Grid>
            </>
          )}
        </Accordion.Panel>
      </Accordion.Item>
    );
  };

  const getManualFeedbackCard = () => {
    return (
      <>
        {/*  <Grid.Col span={3}>
           <Text fz="sm" fw={700}>
            Evidence
          </Text>
        </Grid.Col>
        <Grid.Col span={9}> */}
        {/*    <Paper p="sm" radius="md" withBorder>
            <Text mb="sm" fz="sm" sx={{ whiteSpace: "pre-line" }}>
              {feedbackText && stripHtml(feedbackText.replace("</p><p>","</p>\n<p>"))}
            </Text>
          </Paper> */}
        {/*   </Grid.Col> */}
      </>
    );
  };

  if (requestType === "conversation") {
    return getConversationCard();
  } else if (requestType === "opportunity") {
    return getOpportunityCard();
  } else if (requestType === "manual") {
    return getManualFeedbackCard();
  } else {
    return <></>;
  }
};
