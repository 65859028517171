import { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getIdeasExportData, getRequestsExportData } from "../../Api";
import {
  Badge,
  Box,
  Button,
  Center,
  Group,
  Paper,
  Switch,
  Text,
  Title,
} from "@mantine/core";
import {DatePicker, DatePickerInput} from "@mantine/dates";
import Papa from "papaparse";
import { IconDownload } from "@tabler/icons";
import SyncContext from "../../context/SyncContext";
import moment from "moment";
import {DatePickerValue} from "@mantine/dates/lib/types/DatePickerValue";

type CsvColumn = {
  accessor: string;
  title: string;
  render?: (row: any) => string;
};

const columns: CsvColumn[] = [
  {
    accessor: "_id",
    title: "IdeaId",
  },
  {
    accessor: "title",
    title: "Title",
  },
  {
    accessor: "description",
    title: "Description",
  },
  {
    accessor: "priority",
    title: "Priority",
  },
  {
    accessor: "componentObj.domain",
    title: "Domain",
  },
  {
    accessor: "componentObj",
    title: "ProductArea",
    render: ({ componentObj }: any) =>
      componentObj?.parent?.name ?? componentObj?.name,
  },
  {
    accessor: "componentObj",
    title: "SubArea",
    render: ({ componentObj }: any) =>
      componentObj.parent ? componentObj.name : "",
  },
  {
    accessor: "status",
    title: "Status",
  },
  {
    accessor: "ownerId",
    title: "OwnerId",
  },
  {
    accessor: "owner.name",
    title: "OwnerName",
  },
  {
    accessor: "linkedProductItem.status",
    title: "DeliveryItemStatus",
  },
  {
    accessor: "linkedProductItem.link",
    title: "DeliveryItemLink",
  },
  {
    accessor: "createdAt",
    title: "CreatedAt",
    render: ({ createdAt }: any) => moment(createdAt).format("YYYY-MM-DD"),
  },
  {
    accessor: "updatedAt",
    title: "UpdatedAt",
    render: ({ updatedAt }: any) => moment(updatedAt).format("YYYY-MM-DD"),
  },
  {
    accessor: "internalSubmitter.email",
    title: "BagelSubmitterEmail",
  },
  {
    accessor: "internalSubmitter.name",
    title: "BagelSubmitterName",
  },
];

function getNestedValue(obj: any, path: string): any {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}

const useThisWeekDates = (): any => {
  const now = new Date();
  const firstDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek; // Return the first day of the week as a single Date
};

const ExportIdeas = () => {
  const auth0 = useAuth0();
  const { accountDoc } = useContext(SyncContext);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(0);
  // const [value, setValue] = useState<DatePickerValue>([]);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);


  console.log(value)

  /* const [value, setValue] = useState<Date | null>(null); */
  const [checked, setChecked] = useState(false);

  const downloadCSV = async () => {
    setLoading(true);
  
    // Use `any` for dates in downloadCSV
    const dates: any = checked ? [null, null] : value;
  
    // Ensure `dates` is a valid tuple [Date | null, Date | null]
    const validDates: any [] = Array.isArray(dates)
      ? (dates.length === 2 ? dates : [dates[0] || null, dates[1] || null])
      : [dates || null, null]; // If `dates` is a single value or null, create a tuple
  
    const { ideas } = await getIdeasExportData(auth0, { dates: validDates });
  
    const todaysDate = moment().format("MMM-DD-YYYY");
  
    const mappedData = ideas.map((item: any) => {
      const record: { [key: string]: any } = {};
  
      columns.forEach(({ accessor, title, render }: CsvColumn) => {
        record[title] = render ? render(item) : getNestedValue(item, accessor);
      });
      return record;
    });
  
    const csvFileName = `${accountDoc?.name}_Ideas_${todaysDate}.csv`;
    const csvData = Papa.unparse(mappedData, {
      header: true, // Include headers in the CSV
    });
  
    // Create CSV Blob and trigger download
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
  
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = csvFileName;
  
    document.body.appendChild(a);
    a.click();
  
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setLoading(false);
  }

  return (
    <Paper m="md" radius="md" withBorder>
      <Center>
        <Group py={50} px="md" spacing={50} hidden={active !== 0}>
          <Box
            sx={{
              opacity: checked ? 0.5 : 1,
            }}
          >
            <DatePicker
              type={"range"}
              value={value}
              onChange={setValue}
              /* firstDayOfWeek="sunday" */
              styles={{
                day: {
                  "&[data-selected]": {
                    backgroundColor: "#5B60E3",
                  },
                  "&[data-in-range]": {
                    backgroundColor: "#5b60e321",
                    "&[data-selected]": {
                      backgroundColor: "#5B60E3",
                    },
                  },
                  "&[data-weekend]": {
                    color: `#5B60E3`,
                    "&[data-selected]": {
                      color: "#FFF",
                    },
                  },
                },
              }}
            />
          </Box>

          <Box>
            <Title order={2}>Export ideas data</Title>
            <Text maw={300} my="sm" color="dimmed">
              Please select a date range to customize your ideas data export to
              your needs for accurate analysis or reporting.
            </Text>
            <Group>
              <Button
                onClick={() => downloadCSV()}
                rightIcon={<IconDownload size={16} />}
                loading={loading}
                disabled={!accountDoc?.name}
                styles={(theme) => ({
                  root: {
                    backgroundColor: "#5B60E3",
                    border: 0,
                    height: 40,
                    "&:hover": {
                      backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                    },
                  },
                })}
              >
                Export Data
              </Button>
              <Box>
                <Switch
                  styles={{
                    input: {
                      ":checked": {
                        backgroundColor: "#5B60E3 !important",
                        borderColor: "#5B60E3 !important",
                      },
                    },
                  }}
                  checked={checked}
                  onChange={(event) => setChecked(event.currentTarget.checked)}
                />
                <Text size="xs" color="dimmed">
                  Export all data
                </Text>
              </Box>
            </Group>
          </Box>
        </Group>
      </Center>
    </Paper>
  );
};

export default ExportIdeas;
