import { Flex, Stack, Text } from "@mantine/core"
import { IconX } from "@tabler/icons";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DiscoveryContext from "../../DiscoveryContext";

const CardHeaderDiscovery = () => {
  const {
    setChosenEvidence, cardOpened, setCardOpened
  } = useContext(DiscoveryContext);

  const togglePanel = () => {
    setCardOpened((prev) => !prev);
    setChosenEvidence(null)
  };

  return (
    <>
      
      <Stack fz="20px" fw={600} h='20px' ta='center' justify="center">
        Evidence details
      </Stack>
      <Flex
        mr='8px'
        sx={{ cursor: " pointer" }}
        onClick={togglePanel}
      >
        <IconX size={16} color="#5C5CEB" />
        <Text ml='3px' color="#5C5CEB" fz={12}>Close</Text>
            
      </Flex> 
    
    </>
  )
}

export default CardHeaderDiscovery