import { Box, Text } from "@mantine/core"
import {useContext, useEffect, useState} from "react";
import IdeaContext from "../../IdeaContext";
import DiscoveryContext from "../../../Discovery/DiscoveryContext";
import {getNestedFieldValue} from "../../helpers/calculateImpactResults";
import React from "react";

const ImpactTooltip = ({tooltip, ideaPage}:{tooltip: any, ideaPage: boolean}) => {

  const states = ["assigned", "suggested", "total"];
  const ContextToUse: React.Context<any> =
    ideaPage ? IdeaContext : DiscoveryContext;
  const {
    idea,
    showmatches,
    showValidated,
    ideaId,
    activeInboxTab
  } = useContext(ContextToUse);
 
  const [tooltipText, setTooltipText] = useState('');

  function replaceStateWords(input: string, tabValue: string): string {
    const regex = new RegExp(`\\b(${states.join("|")})\\b`, "g");
    return input.replace(regex, tabValue);
  }

  function parseHtml(text: string) {
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  }

  function fillTemplate(template: string, idea: any, tabName: string): string {

    return template.replace(/<([^>]+)>/g, (_, key) => {
      const keyForTab = replaceStateWords(key, tabName)
      const value = getNestedFieldValue(idea, keyForTab)
      return value ? `<span style='color: #4A4BCC; font-weight: 700'>${value}</span>` : 'N/A';
    });
  }

  useEffect(() => {
    const tabName = !ideaPage
      ? "suggested"
      : showValidated && showmatches
        ? "total"
        : !showValidated && showmatches
          ? "suggested"
          : showValidated && !showmatches
            ? "assigned"
            : "";
    const tooltipTextValue = tooltip ? fillTemplate(tooltip, idea, tabName) : '';
    setTooltipText(tooltipTextValue)
   
  }, [
    ideaId,
    showmatches,
    showValidated,
    activeInboxTab,
  ]);

  return (
    <div>{tooltip &&
      <Box maw={350} >
        <Text lineClamp={16} w={300} maw={300} fz={13.8}>
          {tooltipText.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {parseHtml(line)} {/* Parses inline HTML */}
              <br />
            </React.Fragment>
          )) }
        </Text>
      </Box>}
    </div>
  )
}
export default ImpactTooltip