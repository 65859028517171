import { useState } from "react";
import {
  createStyles,
  Navbar,
  Group,
  UnstyledButton,
  Text,
  Box,
  Collapse,
  Flex,
} from "@mantine/core";
import {
  IconChevronUp,
  IconChevronDown,
  IconDownload,
  IconDatabaseExport,
} from "@tabler/icons";
import { Link, useLocation } from "react-router-dom";
import { CreateNewModal } from "../CreateNewModal";
import { PlusIcon } from "./../../icons/x-symbol-svgrepo-com";
import { useHover } from "@mantine/hooks";
import { mainLinks, settingsLinks } from "./menuObj";

const useStyles = createStyles((theme) => ({
  box: {
    alignItems: "center",
    marginLeft: "8px",
    marginRight: "8px",
    alignSelf: "stretch",
    fontSize: "14px",
    overflow: "hidden",
  },
  footer: {
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    marginTop: "2px",
    marginBottom: "2px",
    borderRadius: "8px",
    padding: "8px",
    display: "flex",
    fontSize: "14px",
    textDecoration: "none",
    color: "#161832",
    fontWeight: 500,
    lineHeight: "20px",
    height: "36px",

    "&:hover": {
      backgroundColor: "#EAEBEE",
    },
  },

  linkIcon: {
    color: "#161832",
  },

  linkActive: {
    color: "#5C5CEB",
    borderRadius: "8px",
    backgroundColor: "#EAEAFE",
  },

  linkDisabled: {
    cursor: "not-allowed",
    "&:hover": {
      color: "#000",
      "& $linkIcon": {  // Updated syntax to target linkIcon within linkDisabled
        color: "#000",
      },
    },
  },
}));

export function MainNav() {
  const { hovered, ref } = useHover();
  const { pathname } = useLocation();
  const menuType: boolean = pathname.indexOf("/settings") === -1;
  const { classes, cx } = useStyles();
  const [modalOpened, setModalOpened] = useState({
    idea: false,
  });
  const [openCollapse, setOpenCollapse] = useState({});
  const toggleCollapse = (key: string) => {
    setOpenCollapse((prev: any) => ({ ...prev, [key]: !prev[key] }));
  };

  let links;
  if (!menuType) {
    links = settingsLinks.map((item: any) => {
      const selectedItem: boolean = pathname === item.link;
      // Assuming item.children contains the child links
      const hasChildren = item.children && item.children.length > 0;
      return (
        <Box className={classes.box}>
          <div key={item.label}>
            {!hasChildren ? (
              <Link
                to={item.link}
                className={cx(classes.link, {
                  [classes.linkActive]: selectedItem,
                })}
                onClick={() => hasChildren && toggleCollapse(item.label)}
              >
                <Flex align="flex-start">
                  {selectedItem ? (
                    <item.activeIcon
                      className={classes.linkIcon}
                      stroke={1.5}
                    />
                  ) : (
                    <item.icon className={classes.linkIcon} stroke={1.5} />
                  )}
                  <Text ml={15}>{item.label}</Text>
                </Flex>
              </Link>
            ) : (
              <>
                <UnstyledButton
                  onClick={() => hasChildren && toggleCollapse(item.label)}
                  w="100%"
                >
                  <Group position="apart">
                    <Box
                      className={cx(classes.link, {
                        [classes.linkActive]: selectedItem,
                      })}
                    >
                      {selectedItem ? (
                        <item.activeIcon
                          className={classes.linkIcon}
                          stroke={1.5}
                        />
                      ) : (
                        <item.icon className={classes.linkIcon} stroke={1.5} />
                      )}
                      <Text ml={15}>{item.label}</Text>
                    </Box>
                    {/* @ts-ignore */}
                    {openCollapse[item.label] ||
                    pathname.includes(item.link) ? (
                        <IconChevronUp size={16} />
                      ) : (
                        <IconChevronDown size={16} />
                      )}
                  </Group>
                </UnstyledButton>

                <Collapse
                  /* @ts-ignore */
                  in={openCollapse[item.label] || pathname.includes(item.link)}
                >
                  {item.children.map((child: any) => {
                    const selectedChildItem: boolean = pathname === child.link;
                    return (
                      <Link
                        key={child.label}
                        to={child.link}
                        className={cx(classes.link, {
                          [classes.linkActive]: selectedChildItem,
                        })}
                      >
                        <Text ml={30}>{child.label}</Text>
                      </Link>
                    );
                  })}
                </Collapse>
              </>
            )}
          </div>
        </Box>
      );
    });
  } else {
    links = mainLinks?.map((item: any) => {
      const selectedItem: boolean =
        (item.link === "/ideas" &&
          !["/inbox", "/reports",'/discovery'].includes(pathname)) ||
        item.link === pathname;

      return (
        <Box key={item.link} className={classes.box}>
          <Link
            key={item.label}
            to={item.link}
            className={cx(classes.link, {
              [classes.linkActive]: selectedItem,
            })}
          >
            <Flex align="center">
              <Box>
                {selectedItem ? (
                  <item.activeIcon className={classes.linkIcon} stroke={1.5} />
                ) : (
                  <item.icon className={classes.linkIcon} stroke={1.5} />
                )}
              </Box>
              <Text
                sx={{
                  transition: "opacity 0.4s ease",
                  opacity: hovered ? 1 : 0,
                }}
                lineClamp={1}
                ml="md"
                fz="14px"
                mb="1px"
              >
                {item.label}
              </Text>
            </Flex>
          </Link>
        </Box>
      );
    });
  }

  return (
    <Navbar width={{ base: menuType ? 56 : 204 }} sx={{ zIndex: 89 }} ref={ref}>
      <Navbar.Section
        grow
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          position: "absolute",
          height: "calc(100vh - 63px)",
          borderRight: "1px solid #D8D8DB",
          transition: "width 0.4s ease-out 0.2s",
          width: menuType ? (hovered ? "204px" : "56px") : "204px",
          fontSize: "14px",
        }}
        py="md"
        bg={"#F8F8FB"}
      >
        <CreateNewModal
          opened={modalOpened["idea"]}
          setOpened={(x) =>
            setModalOpened((prevState) => ({
              ...prevState,
              idea: x,
            }))
          }
        />
        {menuType ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignSelf: "stretch",
              borderBottom: menuType ? "1px solid #D8D8DB" : "",
              paddingBottom: menuType ? "8px" : "o",
              marginBottom: menuType ? "8px" : "o",
              height: "36px",
              fontSize: "14px",
            }}
          >
            <UnstyledButton
              sx={{
                overflow: "hidden",
              }}
              onClick={() => {
                setModalOpened((prevState) => ({
                  ...prevState,
                  idea: !modalOpened["idea"],
                }));
              }}
            >
              <Group noWrap mx="md">
                <Box
                  sx={{
                    borderRadius: 5,
                  }}
                >
                  <PlusIcon />
                </Box>
                <Text
                  fz="14px"
                  fw={500}
                  color="#5C5CEB"
                  sx={{
                    transition: "opacity 0.5s ease",
                    opacity: hovered ? 1 : 0,
                  }}
                  lineClamp={1}
                >
                  Add New Idea{" "}
                </Text>
              </Group>
            </UnstyledButton>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignSelf: "stretch",
              borderBottom: menuType ? "1px solid #D8D8DB" : "",
              paddingBottom: menuType ? "8px" : "o",
              marginBottom: menuType ? "8px" : "o",
              height: "36px",
              fontSize: "14px",
            }}
          >
            <Text fz="14px" mx="md" fw={500}>
              Settings
            </Text>
          </Box>
        )}
        {links}
      </Navbar.Section>
    </Navbar>
  );
}
