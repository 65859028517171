import { Box, Flex, Modal, Text, Tooltip } from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons";
import { useContext, useState } from "react";
import { RequestAssignment } from "./RequestAssignment";
import IdeaContext from "../../../IdeaContext";
import { openModal } from "@mantine/modals";
import LogoIcon from "../../../../layouts/LogoIcon";
import { DeclineModal } from "./DeclineModal";
import { useAuth0 } from "@auth0/auth0-react";
import CommentsMenu from "../Comment/CommentsMenu";

const ValidatedRequestActions = ({ navToPerspective, }: { navToPerspective: any;  }) => {
  const auth0 = useAuth0();
  const { loadEvidence, chosenEvidence, idea } = useContext(IdeaContext);
  
  const [opened, setOpened] = useState(false);
  

  const openDeclineModal = () => {
    setOpened && setOpened(false);
    openModal({
      modalId: "decline",
      title: <LogoIcon />,
      centered: true,
      size: 600,
      children: (
        <DeclineModal
          request={chosenEvidence}
          idea={idea}
          navToPerspective={navToPerspective}
          loadEvidence={loadEvidence}

        />
      ),
    });
  };

  
  
  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ /* borderTop: "1px solid #D8D8DB", */ borderBottom: "1px solid #D8D8DB" }}
      align="center"
    >
      <Modal
        ml='30vw' 
        opened={opened}
        onClose={() => setOpened(false)}
        yOffset={123}
        /* overlayOpacity={0} */
        size="34vw"
        title={
          <Text fz={20} fw={600}>
            Reassign to another idea
          </Text>
        }
      >
        <Box h={440}>
          <RequestAssignment
            idea={idea}
            item={chosenEvidence}
            setOpened={setOpened}
            navToPerspective={navToPerspective}
            TextInputW="100%"
            BoxW="38vw"
          />
        </Box>
      </Modal>
      <CommentsMenu/>
      <Flex align="center">
        <Flex
          align="center"
          w="184px"
          mr="12px"
          sx={{ cursor: " pointer" }}
          onClick={() => setOpened(true)}
        >
          <Box>
            <IconPlus size={15} color="#5C5CEB" />
          </Box>

          <Text ml="4px" color="#5C5CEB" fz={12} lineClamp={1}>
            Reassign to another idea
          </Text>
        </Flex>
        <Box>
          <Tooltip
            label="Decline this request (select a reason in the next step)"
            multiline
            width={200}
            offset={12}
            position="left"
          >
            <Flex
              align="center"
              w="107px"
              mr="12px"
              sx={{ cursor: " pointer" }}
              onClick={() => {
                openDeclineModal();
              }}
            >
              <Box>
                <IconX size={15} color="#5C5CEB" />
              </Box>

              <Text ml="4px" color="#5C5CEB" fz={12} lineClamp={1}>
              Decline
              </Text>
            </Flex>
          </Tooltip>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ValidatedRequestActions;