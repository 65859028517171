import { useContext, useEffect, useState } from "react";
import DiscoveryContext from "./DiscoveryContext";
import { ScrollArea } from "@mantine/core";
import EvidenceCardNiv from "../ideaNiv/body/EvidenceCardNiv";

const EvidenceList = () => {

  const { displayedMatches, displayedRequestsMatches, chosenEvidence , chooseMatch} = useContext(DiscoveryContext);

  const [matches, setMatches] = useState<any[]>([]);

  useEffect(() => {
    const concated = [...displayedMatches, ...displayedRequestsMatches].sort((a, b) => b.rank - a.rank);
   
    setMatches(concated)
    
  }, [displayedMatches, displayedRequestsMatches]);

  return (
    <ScrollArea pt="24px" px={36} w="100%" h={`calc(100vh - 517px)`} mah={`calc(100vh - 517px)`}>
      {matches?.length > 0 &&
          matches?.map((evidence: any, index: number) => (
            <EvidenceCardNiv
              key={evidence._id}
              item={evidence}
              index={index}
              fromSearch={false}
              chosenEvidence={chosenEvidence}
              chooseMatch={chooseMatch}
              ideaPage={false}
            />
          )) 
      }
    </ScrollArea>
  )
}

export default EvidenceList