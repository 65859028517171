import { Flex, Group } from '@mantine/core';
import { useContext } from 'react';
import Sort from '../sort/Sort';
import FiltersContext from '../../../ideas/filters/context/FiltersContext';
import FilterBarDiscovery from './FilterBarDiscovery';

const EvidencesHeader: React.FC = () => {

  const {
    activeFilters,
    setActiveFilters
  } = useContext(FiltersContext);

  return (
    <Group mt={24} mb={12} position='left' px={36}>

      <Group spacing={8}>
        <FilterBarDiscovery 
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
      </Group>

      <Flex>
        {/* <Sort /> */}
      </Flex>

    </Group>
  );
};

export default EvidencesHeader