import { useAuth0 } from "@auth0/auth0-react";
import {
  ActionIcon,
  Box,
  Tooltip,
  Badge,
  createStyles,
  Group, 
  Text
} from "@mantine/core";
import { IconStar } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { getBagelId, updateAccessorByState } from "../../utils";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { replaceNullsWithZeros } from "../../utils/replaceNullsWithZeros";
import { useContext, useEffect, useState } from "react";
import SyncContext from "../../context/SyncContext";
import { SourceIcon } from "../../utils/SourceIcon";
import IdeasContext from "./IdeasContext";
import { TextCell } from "./components/TableCells/TextCell";
import { OwnerCell } from "./components/TableCells/OwnerCell";
import { PriorityCell } from "./components/TableCells/PriorityCell";
import { TemplateCell } from "./components/TableCells/TemplateCell";
import { DynamicCell } from "./components/TableCells/DynamicCell";
import { CommitmentsCell } from "./components/TableCells/CommitmentsCell";

const useStyles = createStyles({
  pagination: {
    direction: "rtl",
    div: {
      direction: "ltr",
      marginRight: "30px",
    },
  },
});

export function RequestsTable({
  requests,
  starClicked,
  loadingStar,
  isAllowedToEditDates,
  handlePageChange,
  totalRecords,
  recordsPerPage,
  page,
  sortStatus,
  setSortStatus,
  loading,
  PAGE_SIZE,
}: {
  requests: any[];
  starClicked: (_id: string, bool: boolean) => void;
  handlePageChange: (newPage: string) => void;
  loadingStar: boolean[];
  isAllowedToEditDates: boolean;
  totalRecords: number | undefined;
  recordsPerPage: number;
  page: number;
  sortStatus: DataTableSortStatus;
  setSortStatus: React.Dispatch<React.SetStateAction<DataTableSortStatus>>;
  loading: boolean;
  PAGE_SIZE: number;
}) {
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const {
    requestPriorities,
    ideaPriorities,
    displayState,
    ideasTableDisplay,
    customEvidenceObj,
  } = useContext(SyncContext);

  const {
    ideaColumns, sortState
  } = useContext(IdeasContext);

  const [columns, setColumns] = useState<any[]>([])

  function updateDataState(accessor: string, wantedState: string): string {
    const aggregationKeyword = "aggregations";
    const states = ["assigned", "suggested", "total"];

    if (!accessor.toLowerCase().includes(aggregationKeyword.toLowerCase())) {
      return accessor;
    }

    const foundState = states.find((state) => accessor.endsWith(state));

    const newAccessor = foundState ? accessor.replace(new RegExp(`${foundState}$`), wantedState) : accessor;

    return newAccessor;
  }

  const dealBreakerObj =
    requestPriorities.find((r: any) => r.key === "Deal Breaker") || {
      key: "Deal Breaker",
      label: "Deal Breaker",
    };

  const { classes } = useStyles();

  const handleOnRowClick = (ideaId: string) => {
    const queryParams = new URLSearchParams(location.search);
    const activeFilters = JSON.parse(queryParams.get("filters") || "[]");

    // Filter activeFilters to include only those whose parent is in evidenceParents
    const filteredFilters = activeFilters.filter((filter: any) =>
      filter.filterObject == "evidence"
    );

    // Serialize the filtered filters back into a string
    const filtersString = JSON.stringify(filteredFilters);

    // Construct the new URL with the filtered filters parameter if it exists and is not empty
    const url = `/idea/${ideaId}${
      filtersString ? `?filters=${encodeURIComponent(filtersString)}` : ""
    }`;

    // Save current filters in sessionStorage
    sessionStorage.setItem("lastFilters", location.search);

    // Navigate to the new URL
    navigate(url);
  };

  const onSortStatusChanged = (sortStatus: DataTableSortStatus) => {
    setSortStatus((prevStatus) => {
      if (prevStatus?.columnAccessor === sortStatus.columnAccessor) {
        return sortStatus;
      } else {
        return { columnAccessor: sortStatus.columnAccessor, direction: "asc" };
      }
    });
  };

  // Map of cell components
  const cellComponents: { [key: string]: React.FC<any> } = {
    TextCell,
    OwnerCell,
    PriorityCell,
    TemplateCell,
    CommitmentsCell,
    DynamicCell
    // Add other cell components as needed
  };

  useEffect(() => {

    const updatedColumns = ideaColumns.map((colItem: any) => {
      const updatedAccessor = updateDataState(colItem.accessor, sortState);
      
      return {
        ...colItem,
        accessor: updatedAccessor,
      };
    });
  
    setColumns(updatedColumns); 
  }, [sortState, ideaColumns]);

  return (
   
    <DataTable
      /* pinLastColumn */
      sx={{
        "thead tr:first-of-type": {
          borderColor: "#dee2e6",
          background: "#F3F4F6",
        },
        "thead th": {
          padding: "6px 10px !important",
        },
        "th[role='button'] svg.mantine-9cumsf": {
          display: "none",
        },
      }}
      classNames={classes}
      
      fetching={loading}
      verticalSpacing="md"
      records={replaceNullsWithZeros(requests)}
      sortStatus={sortStatus}
      onSortStatusChange={onSortStatusChanged}
      idAccessor={"_id"}
      totalRecords={totalRecords}
      recordsPerPage={recordsPerPage}
      page={page}
      onPageChange={(p) => handlePageChange(p.toString())}
      onCellClick={({ record: request, columnIndex }) =>
        columnIndex == 0
          ? starClicked(
            request._id,
            request.starred &&
              auth0.user &&
              request.starred?.includes(getBagelId(auth0.user))
              ? false
              : true
          )
          : handleOnRowClick(request._id)
      }
      columns={[
        {
          title: (
            <ActionIcon disabled>
              <IconStar color="#D1D5DB" size={16} strokeWidth={2} />
            </ActionIcon>
          ),
          accessor: "_id",
          render: (request: any) => (
            <ActionIcon loading={loadingStar[request._id]}>
              <IconStar
                color={
                  request.starred &&
                  auth0.user &&
                  request.starred?.includes(getBagelId(auth0.user))
                    ? "#FDAE1680"
                    : "#D1D5DB"
                }
                fill={
                  request.starred &&
                  auth0.user &&
                  request.starred?.includes(getBagelId(auth0.user))
                    ? "#FDAE1680"
                    : "#fff"
                }
                size={16}
                strokeWidth={2}
              />
            </ActionIcon>
          ),
        },
        {
          accessor: "aggregations.feedbacks.new",
          title: "",
          sortable: true,
          render: ({ aggregations, origin, originId }) => {
            const newCount = aggregations?.requests?.new || 0;
            return (
              <Group spacing="xs" noWrap>
                {newCount > 0 && (
                  <Tooltip
                    position="bottom"
                    sx={{ fontSize: 12 }}
                    label={`${newCount} new evidences`}
                  >
                    <Badge variant="dot">{newCount}</Badge>
                  </Tooltip>
                )}
                {/* Additional content can be added here */}
                {origin && (
                  <Tooltip
                    position="bottom"
                    sx={{ fontSize: 12 }}
                    label={`${origin} sync - ${originId}`}
                  >
                    <Box mb={-2} p={0}>
                      <SourceIcon sourceName={origin} width={16} height={16} />
                    </Box>
                  </Tooltip>
                )}
              </Group>
            );
          },
        },
        // Columns from ideaColumnsConfig
        ...columns
          .filter((col: any) => col.enabled && !col.hidden)
          .sort((a: any, b: any) => a.index - b.index)
          .map((col: any) => {
           
            const CellComponent = cellComponents[col.renderComponent]?
              cellComponents[col.renderComponent] : cellComponents['TextCell']; 

            return {
              accessor: col.renderComponent == 'DynamicCell' ? updateAccessorByState(col.accessor, sortState) : col.accessor ,
              title: <Tooltip label={col?.tooltip} disabled={!col?.tooltip}><Text>{col?.title}</Text></Tooltip>,
              width: col.width,
              sortable: col?.sortable,
              render: (data: any) => (
                <CellComponent
                  data={data}
                  col={col}
                  renderParams={col.renderParams || {}} 
                  ideaPriorities={ideaPriorities}
                  dealBreakerObj={dealBreakerObj} 
                  customEvidenceObj={customEvidenceObj}
                  isAllowedToEditDates={isAllowedToEditDates}
                  ideasTableDisplay={ideasTableDisplay}
                  displayState={displayState}
                  accessor={col.accessor}
                />
              ),
            };
          }),
      ]}
    /> 
  );
}



