import { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ChildProps } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { createNewIdea, getIdeaMatches, getRequestsMatchesForAnIdea, getSuggestionsForIdea , saveIdea} from "../../Api";
import { getCalculatedPerspectiveForRequestDiscoveryNew } from "../ideaNiv/helpers/aggregateRequestCompanyData";
import { SortState } from "../ideaNiv/IdeaContext";
import FiltersContext from "../ideas/filters/context/FiltersContext";
import { sortRequests } from "../ideaNiv/helpers/sortRequests";
import SyncContext from "../../context/SyncContext";
import { getBagelId } from "../../utils";
import { findFilterByName } from "../../context/helpers";


interface DiscoveryContextType {
  ideaId: string | undefined;
  idea: any;
  matches: any[];
  createIdeaDiscovery: (text: string)=> void;
  chosenEvidence: any;
  setChosenEvidence: React.Dispatch<React.SetStateAction<any>>;
  chooseMatch: (id: string, isFromSearch: boolean)=> void;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  perspectiveData: { [key: string]: any };
  getMatches: ()=> void;
  matchesTimer: ()=> void;
  matchesTrigger: number;
  showValidated: boolean;
  showmatches: boolean;
  loadIdeaMatches: (text: string)=> void;
  displayedMatches: any[];
  setDisplayedMatches: React.Dispatch<React.SetStateAction<any[]>>;
  activeSort: SortState | null;
  setActiveSort: React.Dispatch<React.SetStateAction<SortState | null>>;
  cardOpened: boolean;
  setCardOpened:React.Dispatch<React.SetStateAction<boolean>>;
  ideaMatches: any[];
  activeSearch: boolean;
  setActiveSearch: React.Dispatch<React.SetStateAction<boolean>>; 
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>; 
  convertFromDiscoveryToIdea: (idea: any)=> void;
  loadingEvidenceMatches: boolean;
  loadingIdeasMatches: boolean;
  matchesCompanies: any[];
  requestsMatches: any[];
  displayedRequestsMatches: any[]; 
  requestsMatchesCompanies: any[];
}

const DiscoveryContext = createContext<DiscoveryContextType>({} as DiscoveryContextType);

const DiscoveryContextProvider = ({ children }: ChildProps) => {

  const auth0 = useAuth0();
  const nav = useNavigate();
  const { defaultStatus , defaultPriority, perspectiveConfigurations, ideasFiltersConfig } = useContext(SyncContext)

  const { activeFilters, setActiveFilters } = useContext(FiltersContext)
  const [idea, setIdea] = useState<any>(null);
  const [ideaId, setIdeaId] = useState<any>(null);
  const [description, setDescription] = useState<any>('');
  const [matches, setMatches] = useState<any[]>([]);
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const [displayedMatches, setDisplayedMatches] = useState<any[]>([]);
  const [matchesTrigger, setMatchesTrigger] = useState(0); 
  const [chosenEvidence, setChosenEvidence] = useState<any>(null);
  const [ideaMatches, setIdeaMatches] = useState<any[]>([]);
  const [cardOpened, setCardOpened] = useState(false);
  const [activeSort, setActiveSort] = useState<SortState | null>(null);
  const [loadingEvidenceMatches, setLoadingEvidenceMatches] = useState(false)
  const [loadingIdeasMatches, setLoadingIdeasMatches] = useState(false)
  const [requestsMatches, setRequestsMatches] = useState<any[]>([])
  const [displayedRequestsMatches, setDisplayedRequestsMatches] = useState<any[]>([])
  
  const [type, setType] = useState("businessName");
  const [perspectiveData, setPerspectiveData] = useState<{
    [key: string]: any;
  }>({
  });

  const [matchesCompanies, setMatchesCompanies] = useState<any[]>([])
  const [requestsMatchesCompanies, setRequestsMatchesCompanies] = useState<any[]>([])
  const [computed, setComputed] = useState(false)
  const showValidated = false
  const showmatches = true
  

  // Helper function to safely get nested properties
  const getNestedProperty = (object: any, path: any) => {
    return path
      .split(".")
      .reduce(
        (obj: any, key: any) =>
          obj && obj[key] !== undefined ? obj[key] : undefined,
        object
      );
  };

  const loadIdeaMatches = (text: string) => {
    
    getSuggestionsForIdea(text, text, auth0)
      .then((data: any) => {
        setIdeaMatches(data?.ideaMatches || []);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() =>
        setLoadingIdeasMatches(false)
      ); 
  };

  const convertFromDiscoveryToIdea = (ideaObj: any) => {

    saveIdea(ideaObj, auth0).then((res: any)=>{
      console.log(res)
    }).catch((e: any)=>{
      console.log(e)
    }).finally(()=>{
      nav(`/idea/${ideaId}`)
    })
  }

  const matchesTimer = () => {

    if(computed || !ideaId){
    
      if(matchesTrigger < 0){
        setLoadingEvidenceMatches(false)
        return
      }
      else{
        setTimeout(
          () => {
            setMatchesTrigger(-100);
          },
          2000
        );
      }  
    }

    else{
      setTimeout(
        () => {
          setMatchesTrigger(prev => prev + 1);
        },
        2000
      );
    }
  };

  const createIdeaDiscovery = (text: string)=>{

    setActiveFilters([])
    setComputed(false) 
    setIdeaId(null) 
    setLoadingIdeasMatches(true)
    setLoadingEvidenceMatches(true)
    
    const newObj = { idea:
      
      { title: '',
        description: text,
        ownerId: getBagelId(auth0.user),
        status: defaultStatus,
        priority: defaultPriority,
        state: 'draft'}
    };

    createNewIdea(newObj, auth0)
      .then((response) => {
        const newId = response?.idea?._id;
        setIdea(response?.idea)
        if (newId) {
          setIdeaId(newId)
          setMatchesTrigger(1) 
          loadIdeaMatches(text)
        } else {
          console.log(`did not receive request id for new idea`, response);
        }
      })
      .catch(console.log)
  }

  const getMatches = async () => {

    if (ideaId) {
      const res = await getIdeaMatches(ideaId, auth0);
      const matchesRes = res?.matches || [];
      const computeStatus =  res?.computed

      if(computeStatus === 'done'){
        setComputed(true)
      }

      const matchesOrgs = res?.matches?.map((a: any)=> a = a.company).filter((company: any)=> company._id !== 'N/A')
      
      setMatchesCompanies(matchesOrgs)
      setMatches(matchesRes);
      setDisplayedMatches(matchesRes);
      
      if(matchesRes.length > 0){
        setLoadingEvidenceMatches(false)
      }
    }   
  };

  const chooseMatch = (id: string) => {

    setCardOpened(false);
  
    let found = null
    found = matches?.find((m: any) => m?._id === id) || null;

    if(!found){
      found = requestsMatches?.find((m: any) => m?._id === id) || {_id: ''};
    }

    if (found?._id === chosenEvidence?._id) {
      return;
    }
  
    setTimeout(() => {
      setChosenEvidence(found);
      setCardOpened(true);
    }, 68); 
  };


  const getRequestsMatches = async ()=>{
    ideaId && getRequestsMatchesForAnIdea(ideaId, auth0).then((res)=>{
      setRequestsMatches(res)
      setDisplayedRequestsMatches(res)
      const mapped = res?.map((a: any)=> a = a.company).filter((company: any)=> company._id !== 'N/A')
      setRequestsMatchesCompanies(mapped)
    }).catch(console.log)
  }

  async function fetchData() {
    try {
      await getMatches();
      await getRequestsMatches();
      matchesTimer()
    } catch (e) {
      console.log(e);
    }}

  
  useEffect(() => {

    if(ideaId){
      fetchData() 
    }  
  }
  , [matchesTrigger, ideaId]);

  const filterRequests = (requests: any[], filters: any[]) => {
    // Check if filters are present and if any filter has selected items
    if (
      !filters.length ||
      filters.every(
        (filter) => !filter.selected || filter.selected.length === 0
      )
    ) {
      return requests; // Return all requests if no filters are active or selected arrays are empty
    }

    return requests.filter((request) => {
      return filters.every((filter) => {
        const { parent, selected, name } = filter;
        if (!selected || selected.length === 0) return true; // Skip filtering for this filter if no selections

        const CurrentFilterConfig = findFilterByName(name, 'evidence', ideasFiltersConfig)

        // const filterPath = FiltersKeyValMap[parent]?.frontendFilter;
        const filterPath = CurrentFilterConfig?.dbFieldPath 
        // || FiltersKeyValMap[parent]?.frontendFilter;

        if (!filterPath) return true; // Skip this filter if path is not defined

        const requestValue = getNestedProperty(request, filterPath);

        
        if (Array.isArray(requestValue)) {
          return requestValue
            .map((rV) => rV.toLowerCase())
            .some((r) => selected.includes(r.toLowerCase()));
        } else {
          return selected.includes(requestValue?.toLowerCase());
        }
      });
    });
  };
  useEffect(() => {

    const filteredMatches = filterRequests(matches, activeFilters);
    const filteredRequestsMatches = filterRequests(requestsMatches, activeFilters);
    setDisplayedMatches(filteredMatches);
    setDisplayedRequestsMatches(filteredRequestsMatches)

  }, [activeFilters]);


  useEffect(() => {
    if (activeSort) {
      setDisplayedMatches(prev => sortRequests(prev, activeSort));
    }
  }, [activeSort, ideaId]);


  useEffect(() => {

    const allMatches = [...matches, ...requestsMatches]

    getCalculatedPerspectiveForRequestDiscoveryNew(allMatches, perspectiveConfigurations).then((data) => {
      setPerspectiveData((prev) => ({
        ...prev,
        ...data
      }));
    })
      .catch((error: any) => {
        console.error("Error recalculating company data:", error);
      });  
    
  }, [matches, requestsMatches]);


  return (
    <DiscoveryContext.Provider
      value={{
        ideaId,
        idea,
        matches,
        createIdeaDiscovery,
        chosenEvidence,
        chooseMatch,
        type,
        setType,
        perspectiveData,
        getMatches,
        matchesTimer,
        matchesTrigger,
        showValidated,
        showmatches,
        loadIdeaMatches,
        displayedMatches,
        setDisplayedMatches,
        activeSort,
        setActiveSort,
        cardOpened,
        setCardOpened,
        ideaMatches,
        setChosenEvidence,
        activeSearch,
        setActiveSearch,
        description, 
        setDescription, 
        convertFromDiscoveryToIdea, 
        loadingEvidenceMatches,
        loadingIdeasMatches,
        matchesCompanies,
        displayedRequestsMatches,
        requestsMatches,
        requestsMatchesCompanies
      }}
    >
      {children}
    </DiscoveryContext.Provider>
  );
};

export { DiscoveryContext, DiscoveryContextProvider };
export default DiscoveryContext;



