import { Checkbox, Group, Flex, Text, Tooltip } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import IdeaContext from "../../IdeaContext";
import ProgressBarPart from "./ProgressBarPart";
import DiscoveryContext from "../../../Discovery/DiscoveryContext";


export const SingleSideCheckbox = ({
  item,
  validatedCount,
  largestMatchesCount,
  largestInboxCount,
  largestTotal,
  disabled,
  ideaPage
}: {
  item: any;
  validatedCount: number;
  largestMatchesCount: number;
  largestInboxCount: number;
  largestTotal: number;
  disabled?: boolean | undefined
  ideaPage? : boolean
}) => {



  const ContextToUse: React.Context<any> = 
  ideaPage ? IdeaContext : DiscoveryContext;

  const {
    showmatches,
    showValidated,
    activeInboxTab
  } = useContext(ContextToUse);

 
  const [onlyValidatedPercentage, setOnlyValidatedPercentage] = useState(0); 
  const [onlyPendingPercentage, setOnlyPendingPercentage] = useState(0); 
  const [onlyBgPercentage, setOnlyBgPercentage] = useState(0);

  useEffect(() => {
    if(showmatches && showValidated){
      const validatedPerc = item?.requestsCount/largestTotal*100
      setOnlyValidatedPercentage(validatedPerc)
      const perc = (item?.matchesCount + item?.inboxCount)/largestTotal*100
      setOnlyPendingPercentage(perc)
      setOnlyBgPercentage(100 - validatedPerc -perc) 
    } 

    if(!showmatches){
      const validatedPerc = item?.requestsCount/validatedCount*100
      setOnlyValidatedPercentage(validatedPerc)
      setOnlyBgPercentage(100 - validatedPerc)
    }
    if(!showValidated && !activeInboxTab && showmatches){
      const pendingPerc = item?.matchesCount/largestMatchesCount*100
      setOnlyPendingPercentage(pendingPerc)
      setOnlyBgPercentage(100 - pendingPerc)
    }
    if(activeInboxTab){
      const inboxPerc = item?.inboxCount / largestInboxCount*100
      setOnlyPendingPercentage(inboxPerc)
      setOnlyBgPercentage(100 - inboxPerc)
    }

    
  }, [showmatches, showValidated , activeInboxTab, largestInboxCount, largestMatchesCount, largestTotal, validatedCount]);


  return (
    <Checkbox
      styles={{
        label: {
          cursor: "pointer",
        },
        input: {
          cursor: "pointer",
        },
        labelWrapper: {
          width: "-webkit-fill-available",
          cursor: "pointer",
        },
      }}
      my={17.6}
      color="indigo"
      value={item?.value.toLowerCase()}
      disabled={disabled}
      w="100%"
      label={
        <Group position="apart" noWrap>
          <Tooltip label={item?.value}>
            <Text lineClamp={1} fz="12px" fw={500} color="#0A122E">
              {item?.value}
            </Text>
          </Tooltip>
          

          <Group noWrap>
            <Flex
              w="110px"
              h="8px"
              mr="8px"
              sx={{ borderRadius: "8px", overflow: "hidden" }}
            >
              {showValidated && <ProgressBarPart
                widthPercentage={onlyValidatedPercentage}
                color="#5C5CEB"
              />}

              {(showmatches || activeInboxTab) && <ProgressBarPart
                widthPercentage={ onlyPendingPercentage }
                color={"rgba(92, 92, 235, 0.6)"}
              />}

              <ProgressBarPart
                widthPercentage={onlyBgPercentage}
                color="rgba(92, 92, 235, 0.05)"
              />

              {/* <Box
                w={`${item?.validatedPercentage || "0"}%`}
                bg=  "#5C5CEB" 
              ></Box>
              <Box
                w={`${item?.matchesPercentage || "0"}%`}
                bg= {showmatches ? "rgba(92, 92, 235, 0.6)" : "rgba(92, 92, 235, 0.05)"}
              ></Box>
              <Box
                w={`${item?.bgPercentage}%`}
                bg={"rgba(92, 92, 235, 0.05)"}
              ></Box> */}
            </Flex>

            <Flex h={16} w={62} align="center" justify="flex-end">
              {showValidated  && (
                <>
                  <Text fz={39} color="#5C5CEB" mb={22} w={10}>
                    .
                  </Text>
                  <Text
                    color="#5C5CEB"
                    fz="13px"
                    mr="4px"
                    w={15}
                    align="center"
                  >
                    {item?.requestsCount || 0}
                  </Text>
                </>
              )}
              {(showmatches || activeInboxTab) && (
                <>
                  <Text fz={39} color="rgba(92, 92, 235, 0.6)" mb={22} w={10}>
                    .
                  </Text>
                  <Text
                    color="rgba(92, 92, 235, 0.6)"
                    fz="13px"
                    w={15}
                    align="center"
                  >
                    {activeInboxTab ? 
                      item?.inboxCount :
                      (showmatches && showValidated) 
                        ? (item?.matchesCount + item?.inboxCount)
                        : (showmatches && !showValidated) ? (item?.matchesCount):
                        
                          0}
                  </Text>
                </>
              )}
            </Flex>
          </Group>
        </Group>
      }
    />
  );
};